export function GetParentElementByClassName(child: HTMLElement, classname: string): any {
  if (child.className.split(' ').indexOf(classname) >= 0) return child;
  try {
    //Throws TypeError if child doesn't have parent any more
    return (
      child.parentElement && GetParentElementByClassName(child.parentElement, classname)
    );
  } catch (TypeError) {
    return false;
  }
}
