
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import DialogLayout from '@/components/DialogLayout.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import { QuickDialog } from '@/store/models/DialogModel';
import OrderSummaryBreakdown from '@/components/OrderSummaryBreakdown.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { Invoice } from '@/store/models/InvoiceModel';
import { PaymentService } from '@/services/payment-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { OrderBreakdown } from '@/store/models/OrderlineModel';
import { Clone, getDistinctOccurences } from '@/helpers/ObjectHelper';
import { Confirm } from '@/helpers/AuthHelper';

@Component({
  components: { DialogLayout, PaymentTerms, OrderSummaryBreakdown, PaymentSelectCard }
})
export default class extends Vue {
  $refs!: {
    invoiceDialog: HTMLFormElement;
    paymentTerms: HTMLFormElement;
    ConfirmationPayInvoiceDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop()
  invoice!: Invoice;
  @Prop()
  paymentProfileId!: string;
  /* Store Actions */
  @Action('refreshInvoice', { namespace: 'invoices' })
  refreshInvoice!: any;
  /* Watchers */
  @Watch('dialog')
  onDialogChange() {}
  /* Data */
  dialog: boolean = false;
  dialogLayoutData!: QuickDialog;
  isTextInputValid: boolean = false;
  isPaying: boolean = false;
  shouldShowResult: boolean = false;
  invoiceBreakdown: OrderBreakdown = new OrderBreakdown();
  transactionInfo = {
    ResponseCode: '0',
    ResponseMessage: '0',
    Result: '',
    TransactionDateTime: '',
    TransactionId: '',
    WasSuccessful: '',
    Message: ''
  };
  /* Computed */
  get paymentProfile() {
    return this.$refs.paymentTerms.getDefaultPaymentProfile();
  }
  /* Methods */
  async openDialog() {
    this.dialog = true;
    this.isPaying = false;
    this.shouldShowResult = false;
    setTimeout(() => {
      let dialog = document.getElementsByClassName('v-dialog');
      (dialog[0] as HTMLElement).style.cssText += 'border-radius: 15px';
      this.setOrderBreakdown();
    }, 100);
  }
  setOrderBreakdown() {
    let breakdown = Clone(this.invoice.Breakdown);
    if (this.invoice.CCFee > 0) {
      breakdown.Subtotal -= this.invoice.CCFee;
    }
    this.invoiceBreakdown = breakdown;
  }
  onButtonClick() {}
  closeDialog() {
    this.dialog = false;
    this.isPaying = false;
    this.shouldShowResult = false;
  }
  confirmPayment() {
    Confirm(
      () => {
        this.payInvoice();
      },
      'Pay Invoice',
      'Are you sure you want to pay this invoice?',
      'Cancel',
      'I am sure'
    );
  }
  async payInvoice() {
    this.isPaying = true;
    try {
      console.log('paying invoice.');
      let response = await PaymentService.PayInvoice(
        this.$refs.paymentTerms.defaultPaymentProfile.PaymentProfileId,
        this.invoice.Id
      );
      if (response.data.WasSuccessful) {
        this.$emit('refreshInvoice', this.invoice);
        await this.refreshInvoice(this.invoice.Id);
        this.transactionInfo = response.data;
        this.shouldShowResult = true;
        // invalidate store for invoice and reload it.
      } else {
        SetSnackBar(
          `There was a problem trying to process your card: ${response.data.Result}, please contact your bank or select a different card to try.`
        );
      }
    } catch (err) {
      SetSnackBar(
        'There was a problem trying to process your card, please contact your bank or select a different card to try.'
      );
    }
    this.isPaying = false;
  }
  /* Utility Functions */
  hasPaymentMethod() {
    let paymentProfile = this.$refs.paymentTerms?.getDefaultPaymentProfile();
    return this.$refs.paymentTerms && !!paymentProfile && !paymentProfile.IsExpired;
  }
  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
  /* Emmited Functions */
}
